(function ($) {
    'use strict';


    window.addEventListener('DOMContentLoaded', function () {
        /**
         * свапер отзыввов
         */
        const swiper = new Swiper(".js-swiper-about-redesign", {
            slidesPerView: 1,
            spaceBetween: 24,
            navigation: {
                nextEl: '.js-swiper-about-redesign-next',
                prevEl: '.js-swiper-about-redesign-prev',
            },
            pagination: {
                type: 'fraction',
                el: '.js-swiper-about-redesign-pagination'
            }
        });

        /**
         * свапер статей сми
         */
        const smiSwiper = new Swiper('.js-smi-swiper', {
            slidesPerView: 2.005,
            spaceBetween: 24,
            scrollbar: {
                el: '.js-smi-scollbar',
                draggable: true
            },
            breakpoints: {
                1920: {
                    slidesPerView: 2.005,
                },
                1280: {
                    slidesPerView: 2.05,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 1.01,
                    spaceBetween: 12,
                },
                360: {
                    slidesPerView: 1.01,
                    spaceBetween: 12,
                }

            }
        })

        /**
         * дозагрузка сотрудников
         */
        const addEmployeeItems = document.querySelector('.js-employee-add-item');
        const employeeList = document.querySelector('.js-employee-wrapper');
        const addEmployeeItemsText = $(addEmployeeItems).text();
        const textButtonNew = 'Свернуть';
        let positionEmployeeList
        if (addEmployeeItems) {
            addEmployeeItems.addEventListener('click', function () {
                if (employeeList ) {
                    if ($(employeeList).hasClass('active')) {
                        $(employeeList).removeClass('active');
                        $(addEmployeeItems).text(addEmployeeItemsText);
                        bodyScrollBar.scrollTop = positionEmployeeList;
                    } else {
                        $(employeeList).addClass('active');
                        $(addEmployeeItems).text(textButtonNew);
                    }
                }
            })
        }

        /**
         * дозагрузка партнеров
         */
        const addPartners = document.querySelector('.js-about-redesign-partners');
        const partnersList = document.querySelector('.js-about-redesign-partners-wrapper');

        if (addPartners) {
            addPartners.addEventListener('click', function () {
                if (partnersList) {
                    partnersList.classList.add('active');
                    addPartners.classList.add('hidden');
                }
            })
        }

        /**
         * нажатие кнопки видео
         */
        const $videoWrapper = $('.js-about-redesign-video-wrapper');
        if ($videoWrapper) {
            const $videoItems = $videoWrapper.find('.js-about-redesign-video-item');
            $videoItems.on('click', function () {
                const video = $(this).find('.js-about-redesign-video');
                const button = $(this).find('.js-about-redesign-video-play');
                button.addClass('hidden');
                video[0].play();
                video.attr('controls', 'controls');
            })
        }

        const $employeeItemWithDescription = $('.js-about-redesign-employee-info');
        if ($employeeItemWithDescription && $employeeItemWithDescription.length > 0 && window.innerWidth < SVK.breakpoint.desktopMin) {
            $employeeItemWithDescription.on('click', function () {
                $(this).toggleClass('active');
            })
        }

        /**
         * обработчик анимаций
         */
        const lokomotivWrapper = document.querySelector('.js-smooth-scroll');
        const aboutRedesignPage = document.querySelector('.js-about-redesign');
        const $pageWrapper = $('.js-page-wrapper');
        if (!lokomotivWrapper) {
            $('body').removeClass('overflow-hidden')
            return;
        }
        positionEmployeeList = Math.abs($(addEmployeeItems).position().top);
        $pageWrapper.addClass('not-margin');
        const $pageWrapperId = $pageWrapper.find('.js-page-wrapper-id-main');
        $pageWrapperId.attr('id', 'not-main')

        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(ScrollToPlugin);
        let bodyScrollBar = Scrollbar.init(aboutRedesignPage, { damping: 0.1, delegateTo: document});
        bodyScrollBar.setPosition(0, 0);
        bodyScrollBar.track.xAxis.element.remove();
        bodyScrollBar.addListener(ScrollTrigger.update);
        ScrollTrigger.scrollerProxy(aboutRedesignPage, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });
        bodyScrollBar.addListener(ScrollTrigger.update);
        const horizontalSections = gsap.utils.toArray('.js-smooth-scroll')
        horizontalSections.forEach(function (sec, i) {

            const thisPinWrap = sec.querySelector('.js-smooth-scroll-pin');
            const thisAnimWrap = thisPinWrap.querySelector('.js-smooth-scroll-animation-wrap');

            const getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth);

            gsap.fromTo(thisAnimWrap, {
                x: () => thisAnimWrap.classList.contains('to-right') ? 0 : getToValue()
            }, {
                x: () => thisAnimWrap.classList.contains('to-right') ? getToValue() : 0,
                ease: "none",
                scrollTrigger: {
                    trigger: sec,
                    scroller: aboutRedesignPage,
                    pinType: 'transform',
                    start: "100px 40%",
                    end: () => "+=" + thisAnimWrap.scrollWidth,
                    pin: thisPinWrap,
                    invalidateOnRefresh: true,
                    anticipatePin: 1,
                    scrub: true,
                }
            });
        });

        /**
         * анимация цифр
         */
        gsap.utils.toArray(".js-smooth-number").forEach(section => {
            gsap.fromTo(section, {y: 100, opacity: 0}, {
                y: 0,
                opacity: 1,
                ease: "none",
                scrollTrigger: {
                    trigger: section,
                    scroller: aboutRedesignPage,
                    start: "100px 95%",
                    end: "+=150",
                    ease: "power1.inOut",
                    scrub: true,
                }
            });
        });

        gsap.utils.toArray(".js-smooth-number-anim").forEach(block => {
            gsap.from(block, {
                textContent: "0",
                duration: 1,
                ease: "power1.inOut",
                modifiers: {
                    textContent: value => formatNumber(value, 0) + $(block).data('subtext')
                },
                scrollTrigger: {
                    scroller: aboutRedesignPage,
                    trigger: block,
                    start: "100px 85%",
                    end: "+=100",
                    toggleActions: "play none none reverse",
                }
            });
        })

        function formatNumber(value, decimals) {
            let s = (+value).toLocaleString('en-US').split(".");
            return decimals ? s[0] + "." + ((s[1] || "") + "00000000").substr(0, decimals) : s[0];
        }

        /**
         * доп обработчик кнопки скролла вверх
         */

        const $buttonScrollUp = $('.js-button-navigation');
        $buttonScrollUp.each(function () {
            let posititonY = 0;
            $(this).on('click', function () {
                if ($(this).data('id') === 'footer-form') {
                    posititonY = 99999;
                } else {
                    posititonY = 0;
                }
                gsap.to(bodyScrollBar,
                    {
                        duration: 2,
                        delay: .5,
                        ease: 'Power1.easeInOut',
                        scrollTo: {
                            y: posititonY
                        }
                    });
            })
        })
    })
})(jQuery);

