(function ($) {
    'use strict';

    window.SVK = {
        breakpoint: {
            mobileMax: 767,
            tabletMin: 768,
            tabletMax: 1279,
            desktopMin: 1280,
            desktopSmMin: 1280,
            desktopSmMax: 1919,
            desktopLgMin: 1920
        }
    };
})(jQuery);
