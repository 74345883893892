(function ($) {
    'use strict';

    $.parallaxAnimation = function () {
        var $blockAnimation = $('.js-parallax-block');
        $blockAnimation.on('mousemove', function (event) {
            //изменение положения блока, при максимальное отклонении
            var STEP_ANIMATION = 10;

            //находим середину блока
            var x0 = $blockAnimation.width() / 2;
            var y0 = $blockAnimation.height() /2;

            var animateX0 = STEP_ANIMATION / x0;
            var animateY0 = STEP_ANIMATION / y0;

            //координаты позиции блока относительно страницы
            var position = $(this).position();

            //координаты курсора относительно центра блока
            var xPos = event.pageX - (position.left + x0);
            var yPos = event.pageY - (position.top + y0);

            var styleX = -Math.floor(xPos * animateX0);
            var styleY = -Math.floor(yPos * animateY0);

            var $animationImage = $('.js-parallax-animation');
            $animationImage.css({'transform': 'translate('+ styleX + 'px, ' + styleY + 'px)' });
        });

        return this;
    };
})(jQuery);
