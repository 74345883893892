(function($) {
    'use strict';

    $.fn.logoOverlay = function () {
        var $self = $(this);
        var $overlays = $self.find('.header-logo-overlay use');

        var init = function () {
            $self
                .on('mouseenter', function () {
                    // выбираем случайную маску среди всех, за исключением текущей (чтобы не было повторов)
                    var index = Math.floor(Math.random() * $overlays.not('.current').length);
                    $overlays
                        .removeClass('active')
                        .not('.current')
                        .eq(index)
                        .addClass('active current')
                        .siblings().removeClass('current');
                });
        };

        init();

        return this;
    };
})(jQuery);
