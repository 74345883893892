(function($){
    'use strict';

    $.fn.headerPanel = function () {
        var $panel = $(this);
        var $panelBtn = $('.js-header-panel-btn');
        var $header = $panel.closest('.js-header');
        var $navigationButton = $header.find('.js-header-navigation-btn');
        var $headerMenuItems = $header.find('.header-menu-item');

        var updateState = function () {
            $('body').css({
                overflow: $header.hasClass('open') ? 'hidden' : ''
            });

            $headerMenuItems.each(function (index, el) {
                $(el).css('transition-delay', (0.1 * ($headerMenuItems.length - index)) + 's');
                setTimeout(function () {
                    if ($header.hasClass('open')) {
                        $(el).addClass('active');
                    } else {
                        $(el).removeClass('active');
                    }
                }, 150 * $(el).index());
            });
        };

        var init = function () {
            $panelBtn.on('click', function () {
                $header.toggleClass('open');
                updateState();
            });

            $headerMenuItems.on('click', function () {
                $header.removeClass('open');
                updateState();
            });

            $navigationButton.on('click', function () {
                $header.removeClass('open');
                updateState();
            });
        };

        init();

        return this;
    };
})(jQuery);
