(function($) {
    'use strict';

    /**
     * @returns {jQuery}
     */
    $.fn.loadNextPageService = function () {
        var $self = $(this);
        var classes = {
            button: '.js-load-next-page-button',
            buttonWrapper: '.js-load-next-page-button-wrapper',
            list: '.js-load-next-page-list',
            form: '.js-load-next-page-form',
        }
        var $list = $self.find(classes.list);
        var $button = $self.find(classes.button);
        var $buttonWrapper = $self.find(classes.buttonWrapper);
        var $form = $self.find(classes.form);
        var MAIN_URL = '/portfolio';
        var loading = false;
        var LOADER_CLASS = 'loading';
        var $select = $self.find('select');
        var url = window.location.href;

        /**
         * получаем текущую страницу
         */
        var getCurrentPage = function () {
            var $button = $self.find(classes.button);
            var currentPage;
            if (!$button || $button.length === 0) {
                currentPage = null;
            }
            currentPage = $button.data('next-page');
            var $inputPage = $self.find('.js-input-page');
            $inputPage.val(currentPage)
        }

        /**
         * данные для запроса
         * @returns {*}
         */
        var getData = function () {
            getCurrentPage();
            return $form.serialize();
        }

        /**
         * заполняем элементы в список
         * @param items
         */
        var pushListItems = function (items) {
            $list.append(items);
        }

        /**
         * заполняем новую кнопку и вешаем обработчик
         * @param button
         */
        var pushNewButton = function (button) {
            $buttonWrapper.empty();
            $buttonWrapper.append(button);
            var $button = $self.find(classes.button);
            addHandlerShowModeButton($button);
        }

        /**
         * обработчик нажатия на кнопку
         */
        var handlerButtonNextPage = function () {
            if (loading) {
                return;
            }
            loading = true;
            var $button = $self.find(classes.button);
            $button.addClass(LOADER_CLASS);
            var data = getData();
            $.ajax({
                url: url,
                data: data,
                type: 'GET',
                crossDomain: false,
                dataType: 'json',
                success: function (data) {
                    pushListItems(data.itemsHtml);
                    pushNewButton(data.pagerHtml);
                    loading = false;
                },
                error: function (error) {
                    console.log(error)
                    loading = false;
                },
            })

        }

        /**
         * вешаем обработчик на кнопку
         * @param button
         */
        var addHandlerShowModeButton = function (button) {
            $(button).on('click', function () {
                handlerButtonNextPage();
            })
        }

        /**
         * Инициализация
         */
        var init = function () {
            addHandlerShowModeButton($button);
            $self.find('.js-input-page').val('1');
        };

        init();

        return this;
    }

    $('.js-load-next-page').each(function () {
        $(this).data('loadNextPageService', $(this).loadNextPageService());
    });

})(jQuery);
