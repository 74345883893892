/**
 * кастомный select
 * @returns {jQuery}
 */
var CustomSelect = function (element) {
    this.constructor = function () {
        this.$self = $(element);
        this.$input = this.$self.find('input');
        this.classes = {
            head: '.js-custom-select-head',
            count: '.js-custom-select-count',
            dropdown: '.js-custom-select-dropdown',
            option: '.js-custom-select-option',
            submitButton: '.js-custom-select-submit',
            clearButton: '.js-custom-select-clear',
            input: '.js-custom-select-input',
        }

        this.openClass = 'open';
        this.disabledClass = 'disabled';
        this.selectedOption = 'selected'

        this.$head = this.$self.find(this.classes.head);
        this.$dropDown = this.$self.find(this.classes.dropdown);
        this.$count = this.$head.find(this.classes.count);
        this.$options = this.$dropDown.find(this.classes.option);
        this.$submitButton = this.$dropDown.find(this.classes.submitButton);
        this.$clearButton = this.$dropDown.find(this.classes.clearButton);
        this.$input = this.$self.find(this.classes.input);
        this.value = [];
        this.saveValue = [];
    }



    /**
     * обработчик на нажатие
     */
    this.addHandlerHead = function () {
        if (this.$self.hasClass(this.openClass)) {
            this.closeSelect();
        } else {
            this.openSelect();
        }
    }

    /**
     * открытие селекта
     */
    this.openSelect = function () {
        this.$self.addClass(this.openClass);
    }

    /**
     * очистить значения
     */
    this.clearValue = function () {
        this.value = this.saveValue.concat();
        this.checkValueOption();
        this.addValueInput();
        this.addCount();
    }

    this.checkSaveValue = function () {
        return this.saveValue.length > 0;
    }

    /**
     * чистим только выбранные значение
     */
    this.clearSaveValue = function () {
        this.value = [];
        this.saveValue = [];
        this.$options.removeClass(this.selectedOption);
        this.addValueInput();
        this.addCount();
    }

    /**
     * чистим глубокие значения
     */
    this.checkValueOption = function () {
        this.$options.each((_, option) => {
            var $option = $(option);
            var optionValue = $option.data('value');
            var findResult = this.value.find((findItem) => {
                return findItem === optionValue;
            })
            if (!findResult) {
                $option.removeClass(this.selectedOption);
            }
        })
    }

    /**
     * закрытие селекта
     */
    this.closeSelect = function () {
        this.$self.removeClass(this.openClass);
        this.clearValue();
    }

    /**
     * заносим value в input
     */
    this.addValueInput = function () {
        this.$input.val(this.value)
    }

    /**
     * заполняем count
     */
    this.addCount = function () {
        this.$count.text(this.value.length > 0 ? this.value.length : ' ');
    }

    /**
     * есть ли значение
     * @returns {number}
     */
    this.checkCountSelectedOption = function () {
        return (this.value.length > 0);
    }

    this.toggleDisabledStatusClearButton = function () {
        if (this.checkCountSelectedOption()) {
            this.$clearButton.removeClass(this.disabledClass);
        } else {
            this.$clearButton.addClass(this.disabledClass);
        }
    }

    /**
     * обработчик клика на option
     * @param option
     */
    this.handlerOptionClick = function (option) {
        var $option = $(option);
        var optionValue = $option.data('value');
        var findIndexValue = this.value.indexOf(optionValue);
        if (findIndexValue !== -1) {
            $option.removeClass(this.selectedOption);
            this.value.splice(findIndexValue, 1);
        } else {
            this.value.push(optionValue);
            $option.addClass(this.selectedOption);
        }
        this.addValueInput();
        this.addCount();
        this.toggleDisabledStatusClearButton();
    }

    /**
     * вешаем обраотчик на очистить
     */
    this.addHandlerClearAll = function () {
        this.$clearButton.on('click', () => {
            if (this.$clearButton.hasClass(this.disabledClass)) {
                return;
            }
            this.value = [];
            this.saveValue = [];
            this.$options.each((_, element) => {
                var $option = $(element);
                $option.removeClass(this.selectedOption);
            })
            this.addValueInput();
            this.addCount();
            this.$self.closest('form').submit();
        })
    }

    /**
     * вешаем обработчик на option
     */
    this.addHandlerOption = function () {
        this.$options.each((_, element) => {
            var $option = $(element);
            $option.on('click', () => {
                this.handlerOptionClick($option);
            })
        })
    }

    /**
     * первая инициализация
     */
    this.firstAddValue = function () {
        this.$options.each((_, element) => {
            var $option = $(element);
            if ($option.data('selected')) {
                this.handlerOptionClick($option);
            }
        })
    }


    /**
     * Инициализация
     */
    this.init = function () {
        this.addHandlerOption();
        this.firstAddValue();
        this.addHandlerClearAll();
        this.toggleDisabledStatusClearButton();
        this.saveValue = this.value.concat();
    };

    this.constructor();
};
