$(document).ready(function() {
    $(document).on('click', '.call-sidebar, .call-simple-sidebar', function(){
        window.analyticsManager.viewContactForm();
    });

    $(document).on('click', '.js-metric-telephone', function() {
        window.analyticsManager.telephoneClick();
    });

    $(document).on('click', '.js-metric-email', function() {
        window.analyticsManager.emailClick();
    });

    $(document).on('metrics:briefVisitFromContacts', function() {
        window.analyticsManager.briefVisitFromContacts();
    });
    $(document).on('metrics:briefAccepted', function() {
        window.analyticsManager.briefAccepted();
    });
});
