(function ($) {
    'use strict';

    /**
     * Custom file input with a list of selected files
     * @param {Object} [options] - Объект с настройками плагина
     * @returns {jQuery}
     */
    $.fn.fileExtended = function (options) {
        var defaults = {
            maxTotalSize: 0,
            maxFileSize: 15 * 1024 * 1024,
            maxFileCount: 3,
            acceptFileTypes: [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/bmp'
            ],
            fileSizeError: 'Размер некоторых файлов превышает допустимый.',
            fileTypeError: 'Некоторые файлы имеют недопусимый формат.',
            fileCountError: 'Количество файлов превышает допустимое.',
            totalSizeError: 'Суммарный размер выбранных файлов превышает допустимый.'
        };
        var settings = $.extend({}, defaults, options);

        var $container = $(this);
        var $form = $container.closest('form');
        var $label = $container.find('label');
        var $initialInput = $container.find('input[type="file"]');
        var $fileList = $container.find('.js-file-list');
        var $helpBlock = $container.find('.help-block');
        var currentInputIndex = 0;

        // Check for DataTransfer methods support
        var supportsDataTransfer = true;
        try {
            new DataTransfer();
        } catch (err) {
            supportsDataTransfer = false;
        }

        /**
         * Clone original input and append it to label with new ID
         */
        var appendInputCloneToLabel = function () {
            $initialInput
                .clone()
                .attr('id', $initialInput.attr('id') + '-' + currentInputIndex++)
                .appendTo($label);
        };

        /**
         * Get file size string with unit in human-readable format
         * @param {number} size - file size in bytes
         * @returns {string}
         */
        var getFileSizeWithUnit = function (size) {
            var units = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'];
            var power = 0; // initial unit set to Bytes
            var resultSize = size;
            while (resultSize > 1024 && power < units.length) {
                resultSize = resultSize / 1024;
                power++;
            }
            return Number(resultSize.toFixed(2)) + ' ' + units[power];
        };

        /**
         * Get jQuery object array with non-empty inputs
         * @returns {jQuery[]}
         */
        var getNonEmptyInputs = function () {
            return $container.find('input[type="file"]').filter(function (index, el) {
                return !!el.files.length; // get only non-empty inputs
            });
        };

        /**
         * Get array of files from all inputs
         */
        var getFileArray = function () {
            var fileArray = [];
            var $inputs = getNonEmptyInputs();

            $inputs.each(function (index, fileInput) {
                if (fileInput.files.length) {
                    for (var i = 0; i < fileInput.files.length; i++) {
                        fileInput.files[i].inputIndex = index; // linking each file to certain input
                        fileInput.files[i].index = i; // saving file index
                        fileArray.push(fileInput.files[i]);
                    }
                }
            });

            return fileArray;
        };

        /**
         * Validate files
         */
        var validate = function() {
            var fileArray = getFileArray();
            var totalFileSize = 0;
            var isTypeValid = true;
            var isFileSizeValid = true;
            var isTotalSizeValid = true;
            var isCountValid = true;
            var invalidFiles = [];
            var errorMessages = [];

            fileArray.forEach(function(file) {
                if (settings.acceptFileTypes.indexOf(file.type) === -1) {
                    isTypeValid = false;
                    invalidFiles.push(file);
                }
                if (settings.maxFileSize < file.size) {
                    isFileSizeValid = false;
                    invalidFiles.push(file);
                }
                totalFileSize += file.size;
            });
            if (!isTypeValid) {
                errorMessages.push(settings.fileTypeError);
            }
            if (!isFileSizeValid) {
                errorMessages.push(settings.fileSizeError);
            }
            if (settings.maxTotalSize && totalFileSize > settings.maxTotalSize) {
                isTotalSizeValid = false;
                errorMessages.push(settings.totalSizeError);
            }
            if (fileArray.length > settings.maxFileCount) {
                isCountValid = false;
                errorMessages.push(settings.fileCountError);
            }
            return {
                isValid: (isCountValid && isTypeValid && isFileSizeValid && isTotalSizeValid),
                invalidFiles: invalidFiles,
                errorMessages: errorMessages
            };
        };

        /**
         * Generate file list markup
         */
        var renderFileList = function () {
            // Clear existing container
            $container.closest('.form-group').removeClass('has-error');
            $fileList.empty();
            $helpBlock.empty();

            var fileArray = getFileArray();
            var status = validate();

            if (fileArray.length >= settings.maxFileCount) {
                $label.addClass('hidden');
            } else {
                $label.removeClass('hidden');
            }

            if (!status.isValid) {
                $container.closest('.form-group').addClass('has-error');
                status.errorMessages.forEach(function (message) {
                    $helpBlock.append($('<p>', {
                        text: message
                    }));
                });
            }

            fileArray.forEach(function (file) {
                var $listItem = $('<li>', {
                    class: 'file-list-item js-file-list-item',
                    append: [
                        $('<span>', {
                            class: 'file-icon',
                            html: '<svg><use xlink:href="#ic_file"></use></svg>',
                        }),
                        $('<span>', {
                            class: 'file-name',
                            text: file.name,
                            title: file.name
                        }),
                        $('<button>', {
                            class: 'file-remove js-file-remove',
                            type: 'button',
                            html: '<svg><use xlink:href="#ic_close"></use></svg>',
                            data: {
                                'file-index': file.index,
                                'input-index': file.inputIndex
                            }
                        }),
                    ]
                });
                $listItem.toggleClass('invalid', (
                    file.size > settings.maxFileSize ||
                    settings.acceptFileTypes.indexOf(file.type) === -1
                ));
                $listItem.appendTo($fileList);
            });
        };

        /**
         * Initialization
         */
        var init = function () {
            // Clear input on page load
            $initialInput.val('').data('index', 0);

            // Allow selecting only 1 file per input if there is no support for DataTransfer methods
            $initialInput.prop('multiple', supportsDataTransfer);

            appendInputCloneToLabel();

            /**
             * Event listeners
             */
            // Added files to an empty input
            $container.on('change', function (e) {
                if ($(e.target).is('input[type="file"]')) {
                    // Move non-empty input to container element, and create new empty input
                    $(e.target).appendTo($container);
                    appendInputCloneToLabel();
                    renderFileList();
                }
            });

            // Click 'remove' button on file list element
            $container.on('click', '.js-file-remove', function () {
                var $t = $(this);
                var $inputs = getNonEmptyInputs();

                if (supportsDataTransfer) {
                    // If we can manipulate individual files in multi-file input
                    var dt = new DataTransfer();

                    // Exclude removed file from array of all files in certain input
                    var filteredFileArray = getFileArray().filter(function (file) {
                        var isTargetInput = file.inputIndex === $t.data('input-index');
                        var isTargetFile = file.index === $t.data('file-index');
                        return isTargetInput && !isTargetFile;
                    });

                    if (filteredFileArray.length) {
                        // If resulting array is not empty - put files from it into corresponding input ...
                        filteredFileArray.forEach(function (file) {
                            dt.items.add(file);
                        });
                        $inputs.eq($t.data('input-index')).get(0).files = dt.files;
                    } else {
                        // ... otherwise - remove corresponding input
                        $inputs.eq($t.data('input-index')).remove();
                    }
                } else {
                    // If only one file is allowed for each input - remove corresponding input
                    $inputs.eq($t.data('input-index')).remove();
                }

                renderFileList();
            });
        };

        // Initialize with initial conditions
        init();

        this.getFileArray = getFileArray;
        this.renderFileList = renderFileList;
        this.validate = validate;
        return this;
    };
})(jQuery);
