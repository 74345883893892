/**
 }
 * Скрипты для кастомизации футера
 * @type {{goal}}
 */
window.customFooter = (function() {

    /**
     * Скрыть кнопку контактов
     */
    var hideContactsButton = function () {
        var $contactsButton = $('.js-footer-contacts-button');
        $('.footer-contact').show();

        if ($contactsButton.length) {
            var url = location.pathname;

            if (url.indexOf('/portfolio/') !== -1) {
                var newurl = url.replace('/portfolio/', '');
                if (newurl.length > 0) {
                    $('.footer-contact').hide();
                }
            }
        }
    };

    hideContactsButton();

    return {
        hideContactsButton: hideContactsButton,
    }
})();

$(function() {

});
