(function ($) {
    'use strict';

    $.fn.newProject = function () {
        var $self = $(this);
        var selectors = {
            select: '.js-custom-select',
            head: '.js-custom-select-head',
            clearAll: '.js-new-project-clear-all',
        }

        var $selects = $self.find(selectors.select);
        var $firstSelectWrapper = $selects.eq(0);
        var $secondSelectWrapper = $selects.eq(1);
        var $clearAlLButton = $self.find(selectors.clearAll);
        var firstSelect;
        var secondSelect;
        var disabledClass = 'disabled';

        var addHandlerSelect = function () {
            $firstSelectWrapper.find(selectors.head).on('click', function () {
                firstSelect.addHandlerHead();
                secondSelect.closeSelect();
            })

            $secondSelectWrapper.find(selectors.head).on('click', function () {
                secondSelect.addHandlerHead();
                firstSelect.closeSelect();
            })
        }

        var addBlockClearAllButton = function () {
            if (firstSelect.checkSaveValue() || secondSelect.checkSaveValue()) {
                $clearAlLButton.removeClass(disabledClass)
            } else {
                $clearAlLButton.addClass(disabledClass)
            }
        }

        var init = function () {
            firstSelect = new CustomSelect($firstSelectWrapper);
            secondSelect = new CustomSelect($secondSelectWrapper);
            firstSelect.init();
            secondSelect.init();
            addHandlerSelect();
            $clearAlLButton.on('click', function () {
                if (firstSelect.checkSaveValue() || secondSelect.checkSaveValue()) {
                    firstSelect.clearSaveValue();
                    secondSelect.clearSaveValue();
                    $self.find('form').submit();
                }
            })
            addBlockClearAllButton();
        }

        init();
        return this;
    }

    $('.js-new-project').each(function () {
        $(this).data('newProject', $(this).newProject());
    });
})(jQuery);