(function($) {
    'use strict';

    /**
     * Анимация лейблов для полей формы
     * @returns {jQuery}
     */
    $.fn.labelField = function () {
        var $self = $(this);
        var $input = $self.find('input');

        /**
         * Установка фокуса на поле ввода
         */
        var focusField = function () {
            $self.addClass('has-focus');
        };

        /**
         * Сброс фокуса
         */
        var blurField = function () {
            if (!$input.val()) {
                $self.removeClass('has-focus');
            }
        };

        /**
         * Инициализация
         */
        var init = function () {
            // Обработка событий
            $input.on('focus', function () {
                focusField();
            });
            $input.on('blur', function () {
                blurField();
            });
        };

        init();

        return this;
    }
})(jQuery);
