(function ($) {
    'use strict';

    $.accordion = function () {
        const $accordionItems = $('.js-accordion');
        let resizeTimeout;

        const closeAccordionItem = function($item) {
            $item.removeClass('open');

            const $itemContent = $item.find('.js-accordion-content');
            $itemContent.css('min-height', '');
        }

        const openAccordionItem = function($item) {
            $accordionItems.removeClass('open');
            $item.addClass('open');

            const $itemContent = $item.find('.js-accordion-content');
            $itemContent.css('min-height', $itemContent.get(0).scrollHeight);
        }

        $(window).on('resize', () => {
            clearTimeout(resizeTimeout);
            if ($('.js-vacancy-page-new').length) {
                return;
            }
            resizeTimeout = window.setTimeout(() => {
                $accordionItems.each((index, el) => {
                    closeAccordionItem($(el));
                });
            }, 100);
        });

        $('.js-accordion-list').on('click', '.js-accordion', function() {
            const $item = $(this);
            if (!$item.length) {
                return;
            }

            $accordionItems.not($item).each(
                function(index, el) {
                    closeAccordionItem($(el));
                }
            )
            if ($item.hasClass('open')) {
                closeAccordionItem($item);
            } else {
                openAccordionItem($item);
            }
        });

        return this;
    }
})(jQuery);
