(function ($) {
    'use strict';

    document.addEventListener('DOMContentLoaded', function () {
        $('.js-slogan-index').each(function () {
            $(this).addClass('active');
        });

        $('.js-services-index').each(function () {
            var $self = $(this);
            var $paths = $self.find('.js-path');
            var $blocks = $self.find('.js-services-block');

            $paths.each(function (index, el) {
                if (typeof SVK !== 'undefined' && window.innerWidth >= SVK.breakpoint.tabletMin) {
                    $(el).attr('stroke-dasharray', $(el).get(0).getTotalLength());
                    $(el).attr('stroke-dashoffset', $(el).get(0).getTotalLength());
                }
            });

            $(window).on('scroll', function () {
                if (!$self.hasClass('active') && $self.offset().top < (window.pageYOffset + (window.innerHeight * 0.75))) {
                    $self.addClass('active');
                    $self
                        .find('.js-line').addClass('active')
                        .find('.circle').addClass('active');

                    $blocks.each(function (index, el) {
                        setTimeout(function () {
                            $(el).addClass('active');
                        }, index * 250);
                    });
                }
            }).trigger('scroll');
        });
    });
})(jQuery);
