/**
 }
 * Апи для работы с метрикой и аналитикой
 * @type {{goal}}
 */
window.analyticsManager = (function() {

    var self = this;

    /**
     * Логгирование
     * @param message
     */
    var log = function(message) {
        try {
            if (window.console) {
                console.log(message);
            }
        }
        catch (e) {}
    };

    /**
     * Цель в яндекс метрике
     * @param name
     */
    var yandexReachGoal = function(name) {
        try {
            ym(1595923, 'reachGoal', name);
        }
        catch (e) {
            log(e);
        }
    };

    /**
     * Урл страницы
     * @return {string}
     */
    var getPageUrl = function () {
        return location.pathname;
    };

    /**
     * Задержка
     * @param callback
     */
    var delay = function(callback) {
        setTimeout(callback, 1000);
    };

    /**
     * Просмотр элемента портфолио
     */
    var viewPortfolioItem = function() {
        delay(function() {
            log('Событие: Просмотрен кейс');
            yandexReachGoal('View_Case');
            gtag('event', 'Портфолио', { 'event_category' : 'Просмотр кейса', 'event_label' : getPageUrl()});
            fbq('trackCustom', 'View Case', {'page': getPageUrl()});
        });
    };

    /**
     * Просмотр портфолио
     */
    var viewPortfolio = function() {
        delay(function() {
            log('Событие: Просмотрено портфолио');

            yandexReachGoal('View_Portfolio');
            gtag('event', 'Портфолио', { 'event_category' : 'Просмотр списка работ'});
            fbq('trackCustom','View Portfolio');
        });
    };

    /**
     * Просмотр вакансии
     */
    var viewVacancy = function() {
        delay(function() {
            log('Событие: Просмотрена вакансия');

            yandexReachGoal('View_Vacancy');
            gtag('event', 'Вакансии', {'event_category': 'Просмотр вакансии', 'event_label': getPageUrl()});
            fbq('trackCustom', 'View Vacancy', {'page': getPageUrl()});
        });
    };

    /**
     * Просмотр формы обратной связи
     */
    var viewContactForm = function() {
        log('Событие: Открытие формы заявки');

        try {
            yandexReachGoal('Open_Application_Form');
            gtag('event', 'Заявка', {'event_category': 'Открытие формы заявки', 'event_label': getPageUrl()});
            fbq('trackCustom', 'Open Application Form', {'page': getPageUrl()});
            gtag('event', 'click', {
                'event_category': 'Open_Application_Form'
            });
        } catch (e) {
            log(e);
        }

    };

    /**
     * Отправка формы обратной связи
     */
    var sendContactForm = function() {
        log('Событие: Получена заявка');

        try {
            yandexReachGoal('Lead');
            gtag('event', 'Заявка', { 'event_category' : 'Отправлена заявка', 'event_label' : getPageUrl()});
            fbq('trackCustom', 'Lead');
            gtag('event', 'click', {
                'event_category': 'lead'
            });
        } catch (e) {
            log(e);
        }
    };

    /**
     * Изменение урла страницы SPA
     */
    var updatePageUrl = function() {
        try {
            var newUrl = location.pathname;
            gtag('config', 'UA-64172603-1', {'page_path': newUrl});
            ym(1595923, 'hit', newUrl);
            log('Урл изменен на: ' + newUrl);
        } catch (e) {
            log(e);
        }
    };

    /**
     * Клик по номеру телефона
     */
    var telephoneClick = function() {
        try {
            log('Событие: Клик по номеру телефона');
            fbq('trackCustom', 'telephone');
            yandexReachGoal('telephone');
            ga('send', 'event', 'telephone', 'click')
        } catch (e) {
            log(e);
        }
    };

    /**
     * Клик по номеру телефона
     */
    var emailClick = function() {
        try {
            log('Событие: Клик по email адресу');
            fbq('trackCustom', 'e-mail');
            yandexReachGoal('e-mail');
            ga('send', 'event', 'e-mail', 'click')
        } catch (e) {
            log(e);
        }
    };

    /**
     * Переход на страницу брифа из контактов
     */
    var briefVisitFromContacts = function() {
        try {
            log('Событие: Переход на страницу брифа из контактов');
            fbq('trackCustom', 'brief_click');
            yandexReachGoal('brief_click');
            ga('send', 'event', 'brief_click', 'click');
        } catch (e) {
            log(e);
        }
    };

    /**
     * Бриф успешно отправлен
     */
    var briefAccepted = function() {
        try {
            log('Событие: Отправленный бриф');
            fbq('trackCustom', 'brief_succes');
            yandexReachGoal('brief_succes');
            ga('send', 'event', 'brief_succes', 'click')
        } catch (e) {
            log(e);
        }
    };

    /**
     * Отправка формы заявки
     */
    var sendFeedbackForm = function () {
        try {
            ga('send', 'event', 'lead', 'click');
        } catch (e) {
            log(e);
        }
    };

    /**
     * Отправка формы страницы услуг
     */
    var sendServicesForm = function () {
        try {
            log('Событие: Отправленная форма страницы услуг');
            gtag('event', 'click', {
                'event_category': 'marketing'
            });
            fbq('track', 'Lead', {
                content_name: 'Auto Insurance',
                content_category: 'Quote',
                value: 40.00,
                currency: 'USD'
            });
        } catch (e) {
            log(e);
        }
    };

    return {
        viewPortfolioItem: viewPortfolioItem,
        viewVacancy: viewVacancy,
        viewContactForm: viewContactForm,
        sendContactForm: sendContactForm,
        viewPortfolio: viewPortfolio,
        updatePageUrl: updatePageUrl,
        telephoneClick : telephoneClick,
        emailClick : emailClick,
        briefVisitFromContacts : briefVisitFromContacts,
        briefAccepted : briefAccepted,
        sendFeedbackForm: sendFeedbackForm,
        sendServicesForm: sendServicesForm
    }
})();

$(function() {

});
