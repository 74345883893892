(function ($) {
    'use strict';

    window.addEventListener('DOMContentLoaded', function () {
        var $form = $('.js-brief-form-new-page');
        var dt = new DataTransfer();
        $form.find('#phone_number').inputmask('+7(999)999-9999', {
            onBeforeMask: function (val) {
                return val.replace(/\D/ig, '').slice(-10);
            }
        });
        $form.find('#phone_number').attr('inputmode', 'numeric');
        $('#email').attr('inputmode', 'email');

        // Анимация лейблов для текстовых полей
        $form.find('.js-label-field').each(function () {
            $(this).data('labelField', $(this).labelField());
        });

        // Вывод имени выбранного файла
        $form.find('.js-brief-file input').on('change', function() {
            var $input = $(this);
            var $wrapperList = $input.closest('.js-brief-file').find('.js-file-list');
            var deleteFile = '<span class="file-delete--new js-file-delete-new"></span>'
            var getHtmlElement = function (name) {
                return `<span class="brief__file-item js-file-item"><span class="brief__file-item-name js-file-item-name">${name}</span>${deleteFile}</span>`
            }

            for (var i = 0; i < this.files.length; i++) {
                $wrapperList.append(getHtmlElement(this.files.item(i).name))
            }
            for (let file of this.files) {
                dt.items.add(file);
            }
            this.files = dt.files;
            $('.js-file-delete-new').on('click', function () {
                var name = $(this).closest('.js-file-item').find('.js-file-item-name').text();
                $(this).parent().remove();
                for(let i = 0; i < dt.items.length; i++){
                    if(name === dt.items[i].getAsFile().name){
                        dt.items.remove(i);
                        continue;
                    }
                }

                $input.files = dt.files;
            })
        });

        // Сброс ошибки чекбокса
        $form.find('.fieldset-consent').on('change', 'input[type="checkbox"]', function () {
            var $input = $(this);
            var errorClass = 'text-field--error';

            var $inputWrapper = $input.closest('.brief__consent');
            var $errorField = $inputWrapper.find('.brief__consent-error');

            if (!$input.hasClass(errorClass)) {
                return;
            }

            $input.removeClass(errorClass);
            $inputWrapper.removeClass('has-error');
            $errorField.remove();
        });

        // Сброс ошибки текстового поля
        $form.find('.js-label-field').on('input', '.js-text-field', function () {
            var $input = $(this);
            var errorClass = 'text-field--error';

            if (!$input.hasClass(errorClass)) {
                return;
            }

            var $inputWrapper = $input.closest('.js-label-field');

            if ($inputWrapper.hasClass('brief__textarea-wrapper')) {
                $inputWrapper = $inputWrapper.closest('.js-form-redesign-textarea');
            }

            $input.removeClass(errorClass);
            $inputWrapper.removeClass(errorClass)
            $inputWrapper.find('.js-error-text').text('');
        });

        // Сброс ошибки типа работ
        $form.find('.js-checkbox-holder').on('change', 'input[type="checkbox"]', function () {
            var $checkboxHolder = $(this).closest('.js-checkbox-holder');
            var $input = $checkboxHolder.find('.js-checkbox-input');
            var $textErrorHolder = $checkboxHolder.find('.js-error-text');

            $input.removeClass('text-field--error');
            $textErrorHolder.text('');
        });

        // Сброс ошибки бюджета
        $form.find('.js-radio-holder').on('change', 'input[type="radio"]', function() {
            var $radioHolder = $(this).closest('.js-radio-holder');
            var $radioList = $radioHolder.find('.js-radio-list');
            var $textErrorHolder = $radioHolder.find('.js-error-text');

            $radioList.removeClass('text-field--error');
            $textErrorHolder.text('');
        });

        //Сброс ошибки выбора файла
        $form.find('.js-brief-file').on('change', 'input[type="file"]', function () {
            var $fileInputHolder = $(this).closest('.js-brief-file');
            var $fileInput = $(this);
            var $textErrorHolder = $fileInputHolder.find('.js-error-text');

            $fileInput.removeClass('text-field--error');
            $textErrorHolder.text('');
        });

        // Обработка отправки формы
        $form.each(function () {
            var $form = $(this);
            var $button = $form.find('button[type="submit"]');
            var buttonOriginalText = $button.text();
            var buttonSendingText = 'Подождите...';

            var isSending = false;

            $form.on('submit', function (event) {
                event.preventDefault();

                if (isSending === true) {
                    return;
                }

                isSending = true;
                $button.attr('disabled', isSending);
                $button.text(buttonSendingText);

                var ajaxData = new FormData(this);

                $.ajax($form.attr('action'), {
                    method: 'POST',
                    data: ajaxData,
                    dataType: 'json',
                    contentType: false,
                    processData: false
                }).always(function () {
                    isSending = false;
                    $button.attr('disabled', isSending);
                    $button.text(buttonOriginalText);
                }).done(function (response) {
                    // Очистить имеющиеся сообщения об ошибках
                    $form.find('.has-error').removeClass('has-error');
                    $form.find('.text-field--error').removeClass('text-field--error').siblings('span').empty();
                    $form.find('.js-form-redesign-textarea').find('span').empty();

                    // Если форма заполнена неправильно
                    if (response.result === false) {
                        Object.keys(response.errors).forEach(function(field) {
                            var $formField;

                            if (field === 'file[]') {
                                $formField = $form.find('.js-file-input-new')
                            } else {
                                $formField = $form.find('#' + field);
                            }

                            $formField.addClass('text-field--error');
                            $formField.closest('.js-form-redesign-textarea').addClass('text-field--error');
                            $formField.siblings('span').html(response.errors[field]);
                            $formField.closest('.js-form-redesign-textarea').find('span').html(response.errors[field]);
                        });

                        $button.blur();

                        var errorField = $('.has-error, .text-field--error').get(0);

                        if ($form.closest('.popup-container').length) {
                            $(errorField).closest('fieldset').get(0).scrollIntoView();
                        } else {
                            var destination = $(errorField).offset().top - 100;
                            $('html').animate({scrollTop: destination}, 1100);
                        }

                        return;
                    }

                    if ($form.hasClass('js-brief-form-new-page')) {
                        var url = new URL(window.location.href);
                        var newPathName = '/thanks/'
                        window.location.href = url.origin + newPathName;
                    } else {
                        $form.children().not('h1').remove();
                        $form.append($('<div>', {
                            class: 'brief__item-title',
                            html:'Спасибо за&nbsp;обращение! Мы&nbsp;получили ваш запрос и&nbsp;свяжемся с&nbsp;вами. Обычно обработка запроса занимает не&nbsp;больше 24&nbsp;часов.<br/><br/> До&nbsp;связи, мы&nbsp;уже подключаемся к&nbsp;решению вашей задачи!'
                        }));
                    }

                    // Форма отправлена успешно
                    window.analyticsManager.sendContactForm();
                    $(document).trigger('metrics:briefAccepted');
                });
            });
        });
    });
})(jQuery);
