(function ($) {
    'use strict';

    window.addEventListener('DOMContentLoaded', function () {
        var $self = $('.js-service');
        $self.find('.js-file-extended').each(function () {
            var $t = $(this);
            $t.data('file', $t.fileExtended({
                maxFileSize: 15 * 1024 * 1024,
                maxFileCount: 3,
                acceptFileTypes: [
                    'image/jpeg',
                    'image/png',
                    'image/jpg',
                    'image/doc',
                    'image/docx',
                    'image/xls',
                    'image/xsx',
                    'image/pdf',
                    'image/bmp'
                ],
                fileSizeError: 'Размер некоторых файлов превышает допустимый (не более 15 Мб).',
                fileTypeError: 'Некоторые файлы имеют неверный формат.',
                fileCountError: 'Превышено максимальное количество файлов. Доступно не более трёх.'
            }));
        });

        //Анимация лейблов у полей ввода
        $self.find('.js-field').each(function () {
            $(this).data('labelField', $(this).labelField());
        });

        var firstSlide = $self.find('.js-work-item-content').get(0);
        //Открытие первого слайда при загрузке страницы
        $(firstSlide).slideDown();

        //Открытие слайдов в блоке
        $('.marketing-work__header').on('click', function (e) {
            var $currentItem = $(e.target).closest('.marketing-work__item');
            var $itemsWork = $('.marketing-work__item');
            var $contentsItem = $('.js-work-item-content');

            if ($currentItem.hasClass('opened')) {
                $itemsWork.removeClass('opened');
                $contentsItem.slideUp();
            } else {
                $itemsWork.removeClass('opened');
                $contentsItem.slideUp();
                $currentItem.addClass('opened');
                $currentItem.find('.js-work-item-content').slideDown();
            }
        });

        //Маска поля телефона
        $self.find('#phone_number').inputmask('+7(999)999-9999', {
            onBeforeMask: function (val) {
                return val.replace(/\D/ig, '').slice(-10);
            }
        });

        /**
         * Удаляет состояние ошибки
         * @param {jQuery} [field] - jquery поле с ошибкой
         * @param {string} [holderClass] - строка с классом обёртки
         * @param {string} [errorClass] - строка с классом состояния ошибки
         */
        var removeErrorState = function (field, holderClass, errorClass) {
            field.closest(holderClass).removeClass(errorClass);
        };


        //Сброс ошибок текстовых полей ввода
        $self.on('input', 'input[type="text"], textarea', function (e) {
            removeErrorState($(e.target), '.js-field, .js-field-textarea', 'has-error');
        });

        //Сброс ошибок чекбоксов
        $self.on('change', 'input[type="checkbox"]', function (e) {
            removeErrorState($(e.target), '.js-field-consent', 'has-error');
        });

        // Изменения состояние меню выпадашки на мобилке
        $self.on('click', function (e) {
            var $holderHeaderDropdown = $(e.target).closest('.js-navigation-list');
            if ($(e.target).closest('.js-navigation-list-button').length) {
                $holderHeaderDropdown.toggleClass('opened');
            } else {
                $('.js-navigation-list').removeClass('opened');
            }
        });

        const $form = $('.js-services-form');
        const $validateBlocks = $form.find('.js-validate-block');

        $form.on('submit', function (event) {
            event.preventDefault();
            var ajaxData = new FormData(this);
            $.ajax($form.attr('action'), {
                method: 'POST',
                data: ajaxData,
                dataType: 'json',
                contentType: false,
                processData: false,
                success: function (data) {
                    if (data.result === false) {
                        showErrors(data.errors);
                    } else if(data.result === true) {
                        const $formHolder = $form.closest('.js-services-form-holder');

                        $formHolder.children().not('h2').remove();
                        $formHolder.append($('<div>', {
                            class: 'form-connection__success',
                            append: [$('<p>', {
                                html: 'Спасибо за&nbsp;обращение! Мы&nbsp;получили ваш запрос и&nbsp;свяжемся с&nbsp;' +
                                'вами. Обычно обработка запроса занимает не&nbsp;более 24&nbsp;часов.'
                            }), $('<p>', {
                                html: 'До&nbsp;связи! Мы&nbsp;уже подключаемся к&nbsp;решению вашей задачи.',
                            })],
                        }));

                        window.analyticsManager.sendServicesForm();
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            })
        });

        /**
         * Показать ошибки валидации
         *
         * @param errors
         */
        const showErrors = function (errors) {
            $validateBlocks.each(function () {
                const name = $(this).data('name');
                const $helpBlock = $(this).find('.js-help-block');

                $(this).removeClass('has-error');
                $helpBlock.empty();

                if (typeof errors[name] === 'undefined') {
                    return true;
                }

                $(this).addClass('has-error');
                $helpBlock.append(errors[name]);
            })
        };


        const $cards = $(document).find('.js-card');
        const $tabs = $(document).find('.js-tab');

        //находим карту в соответствии с активным табом
        const getActiveCard = function (){
            let count = 0;
            $tabs.each((index, tab) => {
                if ($(tab).hasClass('active')) {
                    count = $tabs.index($(tab));
                }
            });
            return $($cards[count]);
        }

        //скрываем и показываем текст
        $('.js-toggle-wrapper-block').each(function(index, element) {
            const options = {
                onceAnimation: true
            }
            $.fn.collapsibleTextBlocks($(element), options);
        });

        $tabs.each((index, element) => {
            $(element).on('click', () => {
                $tabs.removeClass('active');
                $(element).addClass('active');
                $cards.removeClass('active');
                const $activeCard = getActiveCard();
                if (!$activeCard.length) {
                    return;
                }
                $activeCard.addClass('active');
            });
        });

    });
})(jQuery);
