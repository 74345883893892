(function ($) {
    'use strict';

    $.fn.collapsibleTextBlocks = function ($holder, settings) {
        const  defaultOptions = {
            onceAnimation: false
        }
        const options = $.extend({}, defaultOptions, settings);
        const $wrapper = $holder;

        const showMoreText = function ($wrapperContentCard, heightText) {
            $wrapperContentCard.addClass('show');
            $wrapperContentCard.css({'min-height': heightText});
        }

        const toggleShowText = function ($wrapperContentCard, heightText, maxHeight) {
            if ($wrapperContentCard.hasClass('show')) {
                $wrapperContentCard.removeClass('show');
                $wrapperContentCard.css({'min-height': maxHeight});
            } else {
                showMoreText($wrapperContentCard, heightText);
            }
        }

        $wrapper.on('click', '.js-toggle-height-button', function() {
            const $wrapperContentCard = $wrapper.find('.js-text-wrapper');
            const heightText = $wrapper.find('.js-text').height();
            const maxHeight = $wrapperContentCard.css('max-height');
            if (options.onceAnimation) {
                showMoreText($wrapperContentCard, heightText);
                $(this).addClass('hide');
            } else {
                toggleShowText($wrapperContentCard, heightText, maxHeight);
            }
        });

        return this;
    }
})(jQuery);
