(function ($) {
    'use strict';

    $.applicationForm = function () {

        $('#phone').inputmask('+7(999)999-9999')
                    .attr('inputmode', 'numeric');

        $('#email').attr('inputmode', 'email');

        // Анимация лейблов для текстовых полей
        $('.js-label-field').each(function () {
            $(this).data('labelField', $(this).labelField());
        });


        // Вывод имени выбранного файла
        $('.js-brief-file input').on('change', function() {
            var $input = $(this);
            var $briefFileWrapper = $input.closest('.js-brief-file');
            var $label = $briefFileWrapper.find('.js-file-label');
            if (typeof $input.get(0).files !== 'undefined') {
                $label.text($input.get(0).files[0].name);
                $briefFileWrapper.addClass('active');
            } else {
                $label.text($input.val());
            }
        });

        // Удаление выбранного файла
        $('.js-file-delete').on('click', function () {
            var $buttonDelete = $(this);
            var $briefFileWrapper = $buttonDelete.closest('.js-brief-file');
            var $input = $briefFileWrapper.find('input[type=file]');
            var $textError = $input.siblings('.js-error-text')
            var $label = $briefFileWrapper.find('.js-file-label');

            if (typeof $input.get(0).files !== 'undefined') {
                $input.val('').removeClass('text-field--error');
                $label.text('Прикрепить файл');
                $briefFileWrapper.removeClass('active');
                $textError.text('');
            };
        });

        // Сброс ошибки чекбокса
        $('.fieldset-consent').on('change', 'input[type="checkbox"]', function () {
            var $fieldWrapper = $(this).closest('.brief__consent');
            var $errorField = $fieldWrapper.find('.brief__consent-error');

            if ($fieldWrapper.hasClass('has-error')) {
                $fieldWrapper.removeClass('has-error');
                $errorField.remove();
            }
        });

        // Сброс ошибки текстового поля
        $('.js-label-field').on('input', '.js-text-field', function () {
            if ($(this).hasClass('text-field--error')) {
                var $input = $(this);
                var $inputWrapper = $input.closest('.js-label-field');
                $input.removeClass('text-field--error');
                $inputWrapper.find('span').text('');
            }
        });

        // Сброс ошибки типа работ
        $('.js-checkbox-holder').on('change', 'input[type="checkbox"]', function () {
            var $checkboxHolder = $(this).closest('.js-checkbox-holder');
            var $input = $checkboxHolder.find('.js-checkbox-input');
            var $textErrorHolder = $checkboxHolder.find('.js-error-text');

            $input.removeClass('text-field--error');
            $textErrorHolder.text('');
        });

        // Сброс ошибки бюджета
        $('.js-radio-holder').on('change', 'input[type="radio"]', function() {
            var $radioHolder = $(this).closest('.js-radio-holder');
            var $radioList = $radioHolder.find('.js-radio-list');
            var $textErrorHolder = $radioHolder.find('.js-error-text');

            $radioList.removeClass('text-field--error');
            $textErrorHolder.text('');
        });

        //Сброс ошибки выбора файла
        $('.js-brief-file').on('change', 'input[type="file"]', function () {
            var $fileInputHolder = $(this).closest('.js-brief-file');
            var $fileInput = $(this);
            var $textErrorHolder = $fileInputHolder.find('.js-error-text');

            $fileInput.removeClass('text-field--error');
            $textErrorHolder.text('');
        });

        $('.js-select-application').select2({
            placeholder: '',
            width: '100%',
            addAdditionalField: false,
            dropdownParent: $('.js-custom-select-wrapper'),
        });


        var $form = $('.js-job-application-form');

        // Обработка отправки формы
        $form.on('submit', function (event) {
            event.preventDefault();

            var ajaxData = new FormData(this);

            $.ajax('/ajax/vacancies/application.php', {
                method: 'POST',
                data: ajaxData,
                dataType: 'json',
                contentType: false,
                processData: false
            }).done(function (response) {

                // Очистить имеющиеся сообщения об ошибках
                $form.find('.has-error').removeClass('has-error');
                $form.find('.text-field--error').removeClass('text-field--error').siblings('span').empty();

                if (response.result === false) {
                    Object.keys(response.formErrors).forEach(function(field) {
                        var $formField = $form.find('#' + field);
                        if (field === 'approved') {
                            $formField.closest('label')
                                .addClass('has-error')
                                .prepend($('<p>', {
                                    class: 'brief__consent-error',
                                    text: response.formErrors[field]
                                }));
                        } else {
                            $formField.addClass('text-field--error');
                            $formField.siblings('span').html(response.formErrors[field]);
                        }
                    });
                    $form.find('button[type="submit"]').blur();
                }  else {
                    // Форма отправлена успешно
                    $('.js-job-application-form-wrapper').addClass('success');
                }
            });
        });
    }
})(jQuery);
